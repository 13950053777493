$lt_default: 0 0;
$rt_default: 100% 0;
$rb_default: 100% 100%;
$lb_default: 0 100%;


$lt_clip:
      0 var(--clipK),
      calc(min(var(--clipK) / 15, 2px)) calc(var(--clipK) - min(var(--clipK) / 5, 6px)),
      calc(min(var(--clipK) / 6, 5px)) calc(var(--clipK) - min(var(--clipK) / 3, 10px)),

      calc(var(--clipK) - min(var(--clipK) / 3, 10px)) calc(min(var(--clipK) / 6, 5px)),
      calc(var(--clipK) - min(var(--clipK) / 5, 6px)) calc(min(var(--clipK) / 15, 2px)),
      var(--clipK) 0;

$rt_clip:
      calc(100% - var(--clipK)) 0,
      calc(100% - var(--clipK) + min(var(--clipK) / 5, 6px)) calc(min(var(--clipK) / 15, 2px)),
      calc(100% - var(--clipK) + min(var(--clipK) / 3, 10px)) calc(min(var(--clipK) / 6, 5px)),

      calc(100% - min(var(--clipK) / 6, 5px)) calc(var(--clipK) - min(var(--clipK) / 3, 10px)),
      calc(100% - min(var(--clipK) / 15, 2px)) calc(var(--clipK) - min(var(--clipK) / 5, 6px)),
      100% var(--clipK);

$rb_clip:
      100% calc(100% - var(--clipK)),
      calc(100% - min(var(--clipK) / 15, 2px)) calc(100% - var(--clipK) + min(var(--clipK) / 5, 6px)),
      calc(100% - min(var(--clipK) / 6, 5px)) calc(100% - var(--clipK) + min(var(--clipK) / 3, 10px)),

      calc(100% - var(--clipK) + min(var(--clipK) / 3, 10px)) calc(100% - min(var(--clipK) / 6, 5px)),
      calc(100% - var(--clipK) + min(var(--clipK) / 5, 6px)) calc(100% - min(var(--clipK) / 15, 2px)),
      calc(100% - var(--clipK)) 100%;

$lb_clip:
      var(--clipK) 100%,
      calc(var(--clipK) - min(var(--clipK) / 5, 6px)) calc(100% - min(var(--clipK) / 15, 2px)),
      calc(var(--clipK) - min(var(--clipK) / 3, 10px)) calc(100% - min(var(--clipK) / 6, 5px)),

      calc(min(var(--clipK) / 6, 5px)) calc(100% - var(--clipK) + min(var(--clipK) / 3, 10px)),
      calc(min(var(--clipK) / 15, 2px)) calc(100% - var(--clipK) + min(var(--clipK) / 5, 6px)),
      0 calc(100% - var(--clipK));


.lt {
  clip-path: polygon($lt_clip, $rt_default, $rb_default, $lb_default);
}
.lt_rt {
  clip-path: polygon($lt_clip, $rt_clip, $rb_default, $lb_default);
}
.lt_rb {
  clip-path: polygon($lt_clip, $rt_default, $rb_clip, $lb_default);
}
.lt_lb {
  clip-path: polygon($lt_clip, $rt_default, $rb_default, $lb_clip);
}
.lt_rt_rb {
  clip-path: polygon($lt_clip, $rt_clip, $rb_clip, $lb_default);
}
.lt_rt_lb {
  clip-path: polygon($lt_clip, $rt_clip, $rb_default, $lb_clip);
}
.lt_rt_rb_lb {
  clip-path: polygon($lt_clip, $rt_clip, $rb_clip, $lb_clip);
}


.rt {
  clip-path: polygon($lt_default, $rt_clip, $rb_default, $lb_default);
}
.rt_rb {
  clip-path: polygon($lt_default, $rt_clip, $rb_clip, $lb_default);
}
.rt_lb {
  clip-path: polygon($lt_default, $rt_clip, $rb_default, $lb_clip);
}
.rt_rb_lb {
  clip-path: polygon($lt_default, $rt_clip, $rb_clip, $lb_clip);
}



.rb {
  clip-path: polygon($lt_default, $rt_default, $rb_clip, $lb_default);
}
.rb_lb {
  clip-path: polygon($lt_default, $rt_default, $rb_clip, $lb_clip);
}


.lb {
  clip-path: polygon($lt_default, $rt_default, $rb_default, $lb_clip);
}




